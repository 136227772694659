import 'antd/dist/reset.css';
import '../styles/globals.css'
// import 'antd/dist/antd.css';

import 'animate.css';
import type { ReactElement, ReactNode } from 'react'
import type { AppProps } from 'next/app'
import type { NextPage } from 'next'
import LayoutWrapper from './LayoutWrapper';
import '../styles/style.scss';

import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Error from 'next/error';
import Cookies from 'universal-cookie';
import { ConfigProvider } from 'antd';
import theme from '../theme/themeConfig';

import { polyfillPromiseWithResolvers } from "../helpers/utils";

import 'core-js/full/promise/with-resolvers.js';

polyfillPromiseWithResolvers();


export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  eventId?: number | undefined
}

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());


function MyApp({ Component, pageProps, eventId, ...appProps }: AppPropsWithLayout) {
  
  const isLayoutNeeded = [`/[id]/reset-password`].includes(appProps.router.pathname);

  const TNTContainer = () => <ToastContainer
    position="top-right"
    autoClose={1500}
  // hideProgressBar={false}
  // newestOnTop={false}
  // closeOnClick
  // rtl={true}
  // pauseOnFocusLoss
  // draggable
  // pauseOnHover

  />

  // if (pageProps && pageProps?.statusCode == 404) {
  //   // return <Component {...pageProps} />
  //   return <div className='bg-stone-300'><Error message={'Not Found'} statusCode={pageProps?.statusCode} title={'Not Found'} /></div>
  // }


  
  return <>
    {
       <ConfigProvider theme={theme}>
      {Component.getLayout || isLayoutNeeded ? <>
        <TNTContainer />
        <Component {...pageProps} />
      </> :

        <LayoutWrapper eventId={eventId} >
          <TNTContainer />
          <Component {...pageProps} />
        </LayoutWrapper>}
        </ConfigProvider>
    }
  </>
}

MyApp.getInitialProps = async (cc: any) => {
  //     if (typeof window !== 'undefined') {
  //       return {}
  //     }

  const allCookies = cc.ctx.req?.headers.cookie;
  const cookies = new Cookies(allCookies);

  return {
    eventId: cookies && cookies.get('eventId')
  }
}

export default MyApp